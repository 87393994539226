import { createFormControl } from "solid-forms";
import { For, Show, createMemo, createRenderEffect, splitProps } from "solid-js";

import { directives$ } from "../../directives";
//
import type { FormControlData, FormControlValue } from "../SHARED/_model";
import type { MainProps } from "./_model";
import { solidstate } from ":shared/utils/state-manager";
import ArrowIcon  from './assets/icons/Vector.svg'

export default function Select<V extends FormControlValue, D extends FormControlData>(props: MainProps<V, D>) {
  const [local, others] = splitProps(props, [
    "control",
    "options",
    "placeholder",
    "selectedClass",
    "selectedGroupClass",
    "iconClass",
    "optionClass",
    "optionGroupClass",
    "multiple",
    "placeholderClass",
    "onClick",
    "onselected",
    "onSelected",
  ]);
  // @ts-ignore: unused function 'clickOutside'
  const { clickOutside } = directives$.events;
  const _control = Array.isArray(local.control) ? createFormControl<V, D>(...local.control) : local.control;
  const $selected = createMemo(() => {
    if (!local.multiple) return undefined;
    const result = [];
    if (Array.isArray(_control.value)) {
      result.push(..._control.value);
    } else {
      result.push(_control.value);
    }
    return result;
  });
  const $show_options = solidstate.create(false);
  local.placeholder = local.placeholder ?? "Select...";

  createRenderEffect(() => {
    // console.log("select control is :: ", _control.value);
  });

  function showOptions(e: Event) {
    e.preventDefault();
    if (!_control.isDisabled) {
      $show_options.set((s) => !s);
    }
    if (local.onClick) {
      (local.onClick as Function)(e);
    }
  }

  function selectOption(e: Event, idx: number) {
    e.preventDefault();
    const value = local.options[idx];
    _control.setValue(value);
    $show_options.set(false);
    if (local.onselected) {
      local.onselected(value);
    }
    if (local.onSelected) {
      local.onSelected(value);
    }
  }
  function handleSearching(e) {
    e.preventDefault();
    console.log(e.target.value);
  }

  return (
    <div
      class="relative flex flex-col gap-5px"
      use:clickOutside={() => {
        if ($show_options.unwrap) {
          $show_options.set(false);
        }
      }}
    >
      {/* @ts-ignore */}
      <div
        {...others}
        class={`relative w-full flex flex-col cursor-pointer ${others.class ?? ""} `}
        onClick={(e) => showOptions(e)}
        onblur={() => _control.markTouched(true)}
        onfocus={() => _control.markTouched(true)}
        onfocusin={() => _control.markTouched(true)}
        onfocusout={() => _control.markTouched(false)}
        classList={{
          invalid: !!_control.errors,
          touched: _control.isTouched,
          disabled: _control.isDisabled,
          ...others.classList,
        }}
      >
        <input
          type="text"
          // class=" w-full h-full bg-red"
          hidden
          value={_control.value as any}
          onblur={() => _control.markTouched(true)}
          required={_control.isRequired}
          disabled={_control.isDisabled}
        />
        <div class="relative flex flex-row  w-full h-full min-h-1rem items-center justify-between">
          <div class={`relative flex flex-row selected-group  ${local.selectedGroupClass ?? ""}`}>
            <Show
              when={_control.value !== undefined && _control.value !== null}
              fallback={
                <span class={`!text-black w-full placeholder ${local.placeholderClass ?? ""}`}>
                  {local.placeholder && local.placeholder !== "" ? local.placeholder : "Select An Options"}
                </span>
              }
            >
              <Show
                when={local.multiple}
                fallback={
                  <span class={`whitespace-nowrap selected  ${local.selectedClass ?? ""}`}>
                    {String(_control.value)}
                  </span>
                }
              >
                <For each={$selected()}>
                  {(item, index) => {
                    const arr_length = $selected().length;

                    return <span class={`selected ${local.selectedClass ?? ""}`}>{item}</span>;
                  }}
                </For>
              </Show>
            </Show>
          </div>
          <ArrowIcon
            class={`absolute right-0 w-15px duration-300   ${$show_options.value ? "!rotate-180" : ""}`}
          />
        </div>
      </div>
      <Show when={$show_options.value}>
        <div
          class={`
          flex flex-col w-full !z-100
          border border-2 border-solid 
          ${local.options.length > 5 ? "overflow-y-scroll" : ""}

          option-group

          ${local.optionGroupClass ?? " "}
          `}
        >
          <For each={local.options}>
            {(item, index) => {
              const i = index();
              return (
                <span class={`cursor-pointer option ${local.optionClass ?? ""}`} onclick={(e) => selectOption(e, i)}>
                  {item}
                </span>
              );
            }}
          </For>
        </div>
      </Show>
    </div>
  );
}
